import React from 'react';
import AppointmentFlow from './AppointmentFlow';

import Layout from "../../components/layout"

export default function AppointmentPage() {

  return (
    <Layout pageTitle="Booking" buttonLink="/" buttonTitle="Booking">
    <AppointmentFlow />
    </Layout>
  );
}
